$('document').ready(function () {
    $('.mobile-i-button').click(function () {
        $('body').toggleClass('disable-scroll');
        $(this).toggleClass('ion-navicon-round');
        $(this).toggleClass('ion-close-round');
        $('.the-nav').toggleClass('bg');
        $('.the-nav').toggleClass('mobile-menu-open');
    });

    $(window).scroll(function () {
        if (topOfPage()) {
            $('.the-nav').addClass('at-the-top');
        } else {
            $('.the-nav').removeClass('at-the-top');
        }
    });

    function topOfPage() {
        if ($(window).scrollTop() <= 10) {
            return true;
        } else {
            return false;
        }
    }

    // Accordian javascript
    var isAnimating = false;

    // open first accordion items on load. can't hard code this since its in a cms, der.
    $('.single-accord:first-of-type .b').addClass('current').show();
    $('.single-accord:first-of-type .accordion').addClass('change-color');
    $('.single-accord:first-of-type span').addClass('rotate-icon');

    $('.single-accord .h').on('click', function (e) {
        // limiting scope of interaction to just one carousel when more than one carousels are on the page.
        var $curAccord = $(this).parent().parent();
        if (!isAnimating) {
            isAnimating = true;
            if ($(this).next().hasClass('current')) {
                $curAccord.find('.single-accord .h .plus').removeClass('rotate-icon');
                $curAccord.find('.single-accord .accordion').removeClass('change-color');
                $(this).next().removeClass('current').slideUp(function () {
                    isAnimating = false;
                });
            } else {
                $curAccord.find('.single-accord .b').slideUp().removeClass('current');
                $curAccord.find('.single-accord .h .plus').removeClass('rotate-icon');
                $curAccord.find('.single-accord .accordion').removeClass('change-color');
                $(this).next().addClass('current').slideToggle(function () {
                    isAnimating = false;
                });
                $(this).find('span').addClass('rotate-icon');
                $(this).find('.accordion').addClass('change-color');
            }
        }
    });

    // Home Page Sidebar
    function handleHomepageSidebar(settings) {
        var settings = {
            contentClass: '.tab-content',
            navigationId: '#sub-nav'
        };
        $(settings.contentClass).not(':first').hide();
        $(settings.navigationId).find('li:first').addClass('current-tab');
        $(settings.navigationId).find('a').click(function (e) {
            $(".sidebar-home, #sidebar-sticky").unstick();
            var contentToShow = $(this).attr('href');
            contentToShow     = $(contentToShow);
            e.preventDefault();
            $(settings.navigationId).find('li').removeClass('current-tab');
            $(this).parent('li').addClass('current-tab');
            $(settings.contentClass).hide();
            contentToShow.show();
            handleSticky();
        });
    }
    handleHomepageSidebar();

    // Sticky Sidebar
    function handleSticky() {
        var currentWidth = $(window).width();
        if (currentWidth >= 1200) {
            var defaultBottomSpace     = $(".footer").outerHeight();
            var homeBottomSpace        = $("#home-cta-bottom").outerHeight() + $(".footer").outerHeight() + 70;
            var interiorTwoBottomSpace = $("#sub-cta-bottom").outerHeight() + $(".footer").outerHeight() + $(".section-6").outerHeight() + $(".accordion-sub-page").outerHeight() + 70;
            var interiorOneBottomSpace = $("#interior-cta-bottom").outerHeight() + $(".footer").outerHeight() + $(".interior-one.section-5").outerHeight() + $(".interior-one.section-4").outerHeight() + $(".interior-one.section-3").outerHeight() + $(".interior-one.section-2").outerHeight();
            $(".sticky.sticky-default").sticky({
                topSpacing: 105,
                bottomSpacing: defaultBottomSpace
            });
            $(".sticky.sticky-home").sticky({
                topSpacing: 105,
                bottomSpacing: homeBottomSpace
            });
            $(".sticky.sticky-interior-two").sticky({
                topSpacing: 105,
                bottomSpacing: interiorTwoBottomSpace
            });
            $(".sticky.sticky-interior-one").sticky({
                topSpacing: 105,
                bottomSpacing: interiorOneBottomSpace
            });
        } else {
            $(".sticky").unstick();
        }
    }

    $(window).resize(function () {
        handleSticky();
    });
    handleSticky();

    // wraps scrollable tables on medium and smaller viewports
    function scrollWrap() {
        $('table').wrap('<div class="scroll-wrapper"></div>');
        $('.scroll-wrapper').before('<div class="scroll-header">Swipe to view more content</div>');
    }
    scrollWrap();

    // show or hide table scroll header based on if overflowing its parent
    function handleScrollResize() {
        $('table').each(function (index) {
            var table        = $(this);
            var tableWidth   = table.outerWidth();
            var parentWidth  = table.parent().outerWidth();
            var scrollHeader = table.parent().prevAll('.scroll-header:first');

            if (tableWidth > parentWidth) {
                // element has overflow
                scrollHeader.show();
            } else {
                // element doesn't overflow
                scrollHeader.hide();
            }
        })
    }
    $(window).on("resize",function () {
        handleScrollResize();
    });
    $(document).ready(function () {
        handleScrollResize();
    });

});